import React, { useState, useEffect } from 'react';
import './App.css';
import { Line } from 'react-chartjs-2';
import logo from './ic_blueberry_logo.png';
import Card from '@leafygreen-ui/card';

function App() {

  // When the component mounts, check that the browser supports Bluetooth
  useEffect(() => {
    
  }, []);

  return (
    <div className="app">

      <div id="main-page-container">

                <div className="flex-col">
                    <div className="flex-row buttons-row" style={{position: 'absolute', left: '20px', zIndex: '1'}}>
                        <a href="https://www.blueberryx.com"><img src="https://cdn.shopify.com/s/files/1/0304/7905/7027/files/logo_751c0d51-c8ff-410e-9597-5b27ae561b98_180x.png?v=1598187965" style={{height: '40px', width: '40px', marginTop: '14px'}}/></a>
                    </div>
                    <div style={{width: '100%', marginTop: '-120px', marginBottom: '110px'}}>
                        <img className="image-align" src="https://cdn.shopify.com/s/files/1/0304/7905/7027/files/20220409_bb_website.gif?v=1649525916" style={{width: '155%', height: '100%', zIndex: '-1', marginBottom: '-170px'}}/>
                    </div>
                </div>

                <Card className="card-styles text-area" as="article">
                    <h3>What is fNIRS?</h3>
                    <p>Function near-infrared Spectroscopy (fNIRS) works by using an infrared light and detector with a 
                    wavelength that transmit through the skull and refracts off the surface of brain tissue to be able 
                    to measure oxygenated, deoxygenated and total hemoglobin concentration change. Measuring the change 
                    in hemoglobin concentration over time provides a signal to change in blood flow in cerebral tissue.</p>
                    <br></br>
                    <div className="row-center" style={{textAlign: 'center'}}>
                      <img src="https://cdn.shopify.com/s/files/1/0304/7905/7027/files/NIRS-source-detector.png?v=1630352667" style={{width: 'auto', height:'180px', justifyContent: 'center', textAlign: 'center'}}/>
                    </div>
                    <br></br>
                    <p><a href="http://www.iss.com/resources/pdf/publications/fnirs-history.pdf">This publication </a> 
                    by ISS goes in depth around the history of the sensor for the past 30 years. 
                    <a href="https://core.ac.uk/download/pdf/82888605.pdf">This review article </a> 
                    appearing in Frontiers in Human Neuroscience goes into greater detail how fNIRS works and how HbO (oxygenated hemoglobin) and 
                    HbR (deoxygenated hemoglobin) can be differentially measured based on the spectrum monitored by the sensor.</p>
                    <h5>The above image is from our larger review about how fNIRS works in this <a href="https://medium.com/blueberryx/what-is-fnirs-and-how-does-it-work-1896b9cdf7a7">Blog Post</a></h5>
                </Card>

                <Card className="card-styles text-area" as="article">
                    <h3>Variations of near-infrared spectroscopy (NIRS):</h3>
                    <div className="row-center" style={{textAlign: 'center'}}>
                      <img src="https://cdn.shopify.com/s/files/1/0304/7905/7027/files/NIRS-explanation.png?v=1630337241" style={{width: '100%', justifyContent: 'center', textAlign: 'center'}}/>
                    </div>
                    <br></br>
                    <p>Blueberry currently uses the format of continuous wave NIRS, which enables a low cost version of deep tissue blood flow measurement.</p>
                    <h5>Image reference: <a href="https://www.spectroscopyeurope.com/sites/default/files/articles/NIR-24-4-F2.jpg">SpectroscopyEurope</a></h5>
                </Card>

                <Card className="card-styles text-area" as="article">
                    <h3>What metrics can be determined from fNIRS?</h3>
                    <p>The main visible signal is the change in light associated with each pulse of the heart. The blood flowing into the brain and head each 
                    time the heart beats absorbs the light from <a href="https://blueberryx.com/products/preorder-bio-sensing-eyeglass-with-head-up-feedback">Blueberry</a>, creating a rhythmic pulse signal.
                     From this pulse signal, heart rate, pulse rate variability (PRV), SpO2, VO2 Max can be estimated.</p>
                    <p> Blueberry also offer the ability to measure changes in relative activity/effort which are associated with changes in surface tissue relative oxygenation change of blood flowing in the brain.</p>
                </Card>            

      </div>
   
    </div>
  );
}

export default App;